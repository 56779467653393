<template>
  <div></div>
</template>

<script>

export default {
  name: 'UnsubscribeFront',
  components: {
  },
  mounted() {
    this.loadInfo();
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>
<style>
body {
  background: #fff !important;
  min-width: 320px;
  min-height: 240px;
}
</style>
